<template>
  <div id="inspection-form" v-loading="loadingItem" element-loading-background="rgba(0, 0, 0, 0.3)">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>单位管理</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <!-- <el-form-item label="变配电站：">
              <el-select v-model="requestParam.stationId" :popper-append-to-body="false" clearable placeholder="请选择电站">
                <el-option v-for="item in stationData" :key="item.id" :label="item.stationName"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="单位地址：">
              <el-cascader class="mycascader" popper-class="cascaderPopper" clearable v-model="areaId"
                :options="address"   :props="cascaderProps"></el-cascader>
            </el-form-item>
            <el-form-item label="单位标签：">
              <!-- <el-input v-model="requestParam.unitName" placeholder="请输入单位标签" clearable>
              </el-input> -->
              <el-select v-model="requestParam.companyTag" placeholder="请选择单位标签" clearable class="myFormItem"
                :popper-append-to-body="false">
                <el-option v-for="item in UnitNameList" :key="item.dictName" :label="item.dictName"
                  :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单位名称：">
              <el-input v-model="requestParam.companyName" placeholder="请输入单位名称" clearable>
              </el-input>
            </el-form-item>


            <el-form-item label="联系人：">
              <el-input v-model="requestParam.contacts" placeholder="请输入联系人" clearable>
              </el-input>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            <el-button type="primary" :class="isStringInArray('btnNewCompanyInfo') ? '' : 'btnShowAuthority'"
              icon="el-icon-plus" @click="createInspection">新建单位</el-button>
          </div>
        </div>

        <el-table v-loading='dataLoading' element-loading-text="加载中" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.3)" :cell-style="{ color: '#AED6FF', fontSize: '12px' }"
          :header-cell-style="{ color: '#e8f4ff', fontSize: '14px' }" class="table" :data="tableData" default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" row-key="id">
          <!-- <el-table-column type="selection" width="55">
        <template v-slot:header>
          <el-checkbox v-model="checkedAll" @change="handleCheckedAllChange"></el-checkbox>
        </template>
</el-table-column> -->
          <!-- <el-table-column align="center" type="index" width="50"  >
        <template slot-scope="scope">       
            <el-radio @change="tableRadio(scope.row)"  v-model="selected" :label="scope.row.id">
            {{ '' }}
            </el-radio>
      </template>
    </el-table-column>   -->
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="tableText">
                  {{ dataLoading ? '' : '暂无数据' }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column align="center" label="序号" type="index" :index="getIndex" width="60px">
          </el-table-column>
          <el-table-column align="center" prop="companyName" label="单位名称">
          </el-table-column>
          <el-table-column align="center" prop="parentName" label="父级单位名称">
          </el-table-column>
          <!-- <el-table-column align="center" prop="sort" label="排序">
          </el-table-column> -->
          <el-table-column align="center" prop="unitName" label="单位标签">
            <template slot-scope="scope">
              {{ scope.row.companyTag == 1 ? '管理单位' : '运维单位' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="address" label="详细地址">
          </el-table-column>
          <el-table-column align="center" prop="contacts" label="负责人">
          </el-table-column>
          <!-- <el-table-column align="center" prop="phone" label="联系方式">
          </el-table-column> -->
          <el-table-column align="center" prop="createTime" label="创建时间">
          </el-table-column>
          <el-table-column align="center" prop="data" fixed="right" label="操作" width="180px">
            <template slot-scope="scope">
              <el-button @click="editDialog(scope.row)" type="text"
                :class="isStringInArray('btnEdit') ? '' : 'btnShowAuthority'">编辑</el-button>
              <span style="color: #409EFF;" :class="isStringInArray('btnEdit') ? '' : 'btnShowAuthority'">|</span>
              <el-button @click="lookDialog(scope.row)" type="text">详情</el-button>
              <span style="color: #409EFF;" :class="isStringInArray('btnDelete') ? '' : 'btnShowAuthority'">|</span>
              <el-button @click="deleteDialog(scope.row)"
                :class="isStringInArray('btnDelete') ? '' : 'btnShowAuthority'" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model.number="inputValue" size="mini" maxlength="2" class="pagination-input" @input="handleEdit"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog @close="closeDialog('form')" :title="dialogTotalType == 0 ? '新建单位' : '编辑单位'"
      :visible.sync="dialogVisible" width="751px">
      <div class="dialog-content">
        <div class="form-title"></div>
        <el-form class="" ref="form" :rules="rules" :model="form" label-width="100px">
          <el-form-item label="父级单位:" props="parentId" class="myDialogForm">
            <el-cascader class="mycascader" popper-class="cascaderPopper" clearable v-model="form.parentId"
              :options="Hierarchy" :props="HierarchyProps" @change="setSelectedNode"></el-cascader>
          </el-form-item>
          <el-form-item label="单位标签:" prop="companyTag" class="myDialogForm">
            <el-select v-model="form.companyTag" placeholder="请选择单位标签" clearable class="myFormItem"
              :popper-append-to-body="false">
              <el-option v-for="item in UnitNameList" :key="item.dictValue" :label="item.dictName"
                :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单位名称:" prop="companyName" class="myDialogForm">
            <el-input maxlength="20" v-model.trim="form.companyName" class="myFormItem"
              placeholder="请输入单位名称"></el-input>
          </el-form-item>



          <!-- <el-form-item label="排序:" prop="sort" class="myDialogForm">
            <el-input v-model="form.sort" class="myFormItem" placeholder="请输入排序"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="区域：" prop="provinceId" class="myDialogForm"> -->
          <!-- <el-row>
            <el-col :span="8">
            <el-select @change="provinceIdChangeInput" v-model="form.provinceId" clearable class="myFormItem"
              :popper-append-to-body="false" placeholder="请选择省">
              <el-option v-for="item in address" :key="item.id" :label="item.shortName" :value="item.id"></el-option>
            </el-select>
            </el-col>
            <el-col :span="8">
              <el-select @change="cityIdChangeInput" v-model="form.cityId" placeholder="请选择市" clearable
                  class="myFormItem" :popper-append-to-body="false">
                  <el-option v-for="item in addressCityId" :key="item.id" :label="item.shortName"
                    :value="item.id"></el-option>
                </el-select>
            </el-col>
            <el-col :span="8">
              <el-select v-model="form.areaId" placeholder="请选择区" clearable class="myFormItem"
                  :popper-append-to-body="false">
                  <el-option v-for="item in addressAreaId" :key="item.id" :label="item.shortName"
                    :value="item.id"></el-option>
                </el-select>
            </el-col>
            </el-row> -->
          <!-- </el-form-item> -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="省：" prop="provinceId" class="myDialogForm">
                <el-select @change="provinceIdChangeInput" v-model="form.provinceId" clearable class="myFormItem"
                  :popper-append-to-body="false" placeholder="请选择省">
                  <!-- <el-option label="所有" value="2"></el-option> -->
                  <el-option v-for="item in address" :key="item.id" :label="item.shortName"
                    :value="item.id"></el-option>

                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="市：" prop="cityId" class="myDialogForm">
                <el-select @change="cityIdChangeInput" v-model="form.cityId" placeholder="请选择市" clearable
                  class="myFormItem" :popper-append-to-body="false">
                  <el-option v-for="item in addressCityId" :key="item.id" :label="item.shortName"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="区：" prop="areaId" class="myDialogForm">
                <el-select v-model="form.areaId" placeholder="请选择区" clearable class="myFormItem"
                  :popper-append-to-body="false">
                  <el-option v-for="item in addressAreaId" :key="item.id" :label="item.shortName"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
          <el-form-item label="详细地址:" prop="address" class="myDialogForm">
            <el-input v-model.trim="form.address" class="myFormItem" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <el-form-item label="负责人:" class="myDialogForm" prop="contacts">
            <!-- <el-input  v-model.trim="form.contacts" class="myFormItem" placeholder="请输入联系人"></el-input> -->
            <el-select :popper-append-to-body="false" clearable v-model="form.contacts" class="myFormItem"
              placeholder="请选择负责人">
              <el-option v-for="item in userContactsList" :key="item.id" :label="item.userName+' '+ item.appellation"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.companyTag == 2" label="执行人:" class="myDialogForm" prop="opeComUserIds">
            <el-select multiple :popper-append-to-body="false" clearable v-model="form.opeComUserIds" class="myFormItem"
              placeholder="请选择执行人">
              <el-option v-for="item in userList" :key="item.id" :label="item.userName+' '+ item.appellation" :value="item.id"></el-option>
            </el-select>

          </el-form-item>
          <!-- <el-form-item label="联系方式:" prop="phone" class="myDialogForm">
            <el-input maxlength="11" onkeyup="value=value.replace(/^0+(\d)|[^\d]+/g,'')" v-model.trim="form.phone" class="myFormItem" placeholder="请输入联系方式"></el-input>
          </el-form-item> -->
          <el-form-item label="备注:" prop="remark" class="myDialogForm">
            <el-input type="textarea" v-model.trim="form.remark" :rows="3" class="myFormItem" placeholder="请输入备注"></el-input>
          </el-form-item>

        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDialogVisible('form')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="查看单位" @close="closeDialog('form')" :visible.sync="dialogVisible3" width="751px">
      <div class="dialog-content">
        <div class="form-title"></div>
        <el-form class="" ref="form" :model="formLook" label-width="100px">
          <el-form-item label="单位标签:" prop="companyTag" class="myDialogForm">
            <el-select :disabled="true" v-model="formLook.companyTag" placeholder="请选择单位标签" clearable class="myFormItem"
              :popper-append-to-body="false">
              <el-option v-for="item in UnitNameList" :key="item.dictValue" :label="item.dictName"
                :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单位名称:" prop="companyName" class="myDialogForm">
            <el-input :disabled="true" v-model="formLook.companyName" class="myFormItem"
              placeholder="请输入单位名称"></el-input>
          </el-form-item>
          <el-form-item v-if="formLook.companyTag == 1" label="父级单位:" props="parentId" class="myDialogForm">
            <el-cascader class="mycascader" popper-class="cascaderPopper" clearable v-model="formLook.parentId"
              :options="Hierarchy" :props="HierarchyProps" :disabled="true" @change="setSelectedNode"></el-cascader>
          </el-form-item>


          <!-- <el-form-item label="排序:" prop="sort" class="myDialogForm">
            <el-input :disabled="true" v-model="form.sort" class="myFormItem" placeholder="请输入排序"></el-input>
          </el-form-item> -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="省：" prop="provinceId" class="myDialogForm">
                <el-select :disabled="true" @change="provinceIdChange" v-model="formLook.provinceId" clearable
                  class="myFormItem" :popper-append-to-body="false" placeholder="请选择省">
                  <!-- <el-option label="所有" value="2"></el-option> -->
                  <el-option v-for="item in address" :key="item.id" :label="item.shortName"
                    :value="item.id"></el-option>

                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="市：" prop="cityId" class="myDialogForm">
                <el-select :disabled="true" @change="cityIdChange" v-model="formLook.cityId" placeholder="请选择市"
                  clearable class="myFormItem" :popper-append-to-body="false">
                  <!-- <el-option label="所有" value="2"></el-option> -->
                  <el-option v-for="item in addressCityId" :key="item.id" :label="item.shortName"
                    :value="item.id"></el-option>

                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="区：" prop="areaId" class="myDialogForm">
                <el-select :disabled="true" v-model="formLook.areaId" placeholder="请选择区" clearable class="myFormItem"
                  :popper-append-to-body="false">
                  <!-- <el-option label="所有" value="2"></el-option> -->
                  <el-option v-for="item in addressAreaId" :key="item.id" :label="item.shortName"
                    :value="item.id"></el-option>
                  <!-- <el-option label="停用" :value="1"></el-option> -->
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
          <el-form-item label="详细地址:" prop="address" class="myDialogForm">
            <el-input :disabled="true" v-model="formLook.address" class="myFormItem" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <el-form-item label="负责人:" class="myDialogForm" prop="contacts">
            <!-- <el-input  v-model.trim="form.contacts" class="myFormItem" placeholder="请输入联系人"></el-input> -->
            <el-select :disabled="true" :popper-append-to-body="false" clearable v-model="formLook.contacts"
              class="myFormItem" placeholder="请选择负责人">
              <el-option v-for="item in userContactsList" :key="item.id" :label="item.userName+' '+ item.appellation"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="执行人:" class="myDialogForm" prop="opeComUserIds">
            <el-select :disabled="true" multiple :popper-append-to-body="false" clearable
              v-model="formLook.opeComUserIds" class="myFormItem" placeholder="请选择执行人">
              <el-option v-for="item in userList" :key="item.id" :label="item.userName +' '+ item.appellation" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注:" prop="remark" class="myDialogForm">
            <el-input :disabled="true" type="textarea" v-model="formLook.remark" class="myFormItem"
              placeholder="请输入备注"></el-input>
          </el-form-item>

        </el-form>

      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible3 = false">确 定</el-button> -->
      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="delete">确定要删除该单位吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteInspectionForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { inspectionFormApi } from "@/api/inspectionForm.js";
import { stationName, companyInfoList, companyInfoAddress, searchDictCodeList, companyInfoAdd, companyInfoUpdate, companyInfoDelete, queryCompanyHierarchy, getUsersCompany } from "@/api/eios.js"

export default {
  name: "",
  data() {
    return {
      dataLoading: false,
      loadingItem: false,
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        // stationId: "",
        areaId: null,//区
        cityId: null,//市
        provinceId: null,//省
        companyName: "",
        companyTag: "",
        contacts: '',//联系人
      },
      form: {
        companyName: '',
        companyTag: '',
        sort: '',
        provinceId: '',
        cityId: '',
        areaId: '',
        address: '',
        contacts: '',
        opeComUserIds: [],
        remark: '',
        parentId: '',
      },
      formLook: {
        companyName: '',
        companyTag: '',
        sort: '',
        provinceId: '',
        cityId: '',
        areaId: '',
        address: '',
        contacts: '',
        phone: '',
        remark: '',
        parentId: '',
      },
      rules: {

        companyName: [
          { required: true, message: '请输入单位名称', trigger: 'blur' },
        ],
        companyTag: [
          { required: true, message: '请选择单位标签', trigger: 'change' },
        ],
        // sort: [
        //   { required: true, message: '请输入排序', trigger: 'blur' },
        // ],
        // contacts: [
        //   { required: true, message: '请选择负责人', trigger: 'change' },
        // ],
        // opeComUserIds: [
        //   { required: true, message: '请选择执行人', trigger: 'blur' },
        // ],
        provinceId: [
          { required: true, message: '请选择省', trigger: 'change' }
        ],
        cityId: [
          { required: true, message: '请选择市', trigger: 'change' }
        ],
        areaId: [
          { required: true, message: '请选择区', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],


      },
      userContactsList: [],
      userList: [],
      total: 0,
      inputValue: "",
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      detailsData: {},
      id: "",
      inspectionItem: [],
      stationData: [],
      dialogTotalType: 0,
      checkedAll: null,
      address: [],
      addressCityId: [],
      addressAreaId: [],
      areaId: [],
      UnitNameList: [],
      selected: '',
      parentId: 0,
      Hierarchy: [],
      HierarchyProps: {
        children: 'companyInfoList',
        value: 'id',
        label: 'companyName',
        checkStrictly: true,
        emitPath: false,
        checkStrictly: true,
        expandTrigger: 'click',
      },
      cascaderProps: {
        children: 'regionEntityList',
        value: 'id',
        label: 'shortName',
        checkStrictly: true,
        expandTrigger: 'click',
      },
      authorityListBtn: [],
    };
  },
  created() {
    this.$store.commit('increment', 'Eios控制面板')
    this.$store.commit('selectChild', '单位管理')
    this.$store.commit("selectChildren", "");
    this.dataLoading = false
    // this.getList()
  },

  mounted() {
    this.queryOperation()
    this.getAddress()
    this.getUnitNameList()
    // this.obtainUserList()
    this.authorityListBtn = sessionStorage.getItem('roleInfo')
    // this.getCom()
  },

  methods: {
    //按钮权限
    isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },
    //获取单位下的用户
    // getUsersCompany(){

    // },
    //获取用户列表
    obtainUserList(val, type) {

      getUsersCompany().then(res => {
        this.userList = res.data
      })
    },
    //获取父级最后一集
    setSelectedNode(val) {
      console.log(val);
      // this.form.parentId = val[val.length - 1];
      let obj = {
        companyId: val,
        operationFlage: 1,
      }
      getUsersCompany(obj).then(res => {
        this.userContactsList = res.data
      })
      if (this.form.contacts) {
        this.form.contacts = ''
      }
      let obj1 = {
        companyId: val,
        operationFlage: 2,
      }
      getUsersCompany(obj1).then(res => {
        this.userList = res.data
      })
      if (this.form.opeComUserIds) {
        this.form.opeComUserIds = ''
      }
      // this.obtainUserList(val,2)

    },
    setSelectedDetailsNode(val) {
      console.log(val);
      // this.form.parentId = val[val.length - 1];
      let obj = {
        companyId: val,
        operationFlage: 1,
      }
      getUsersCompany(obj).then(res => {
        this.userContactsList = res.data
      })
      let obj1 = {
        companyId: val,
        operationFlage: 2,
      }
      getUsersCompany(obj1).then(res => {
        this.userList = res.data
      })

      // this.obtainUserList(val,2)

    },
    //获取单位层级
    getCom() {
      queryCompanyHierarchy().then(res => {
        this.Hierarchy = res.data
      })
    },
    //获取省市区
    getAddress() {
      this.loadingItem = true
      companyInfoAddress().then(res => {
        console.log('~~~~~~~~', res.data[0].regionEntityList);
        this.address = res.data[0].regionEntityList
        this.loadingItem = false
      })
    },
    //table选中
    handleCheckedAllChange(val) {
      console.log(val);
    },

    isOverflow(content) {
      const lineHeight = 16;
      const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
      // console.log("====>",numLines);
      return numLines >= 3;
    },
    //序号
    getIndex(index) {
      return (this.requestParam.pageNum - 1) * this.requestParam.pageSize + index + 1;
    },
    closeDialog(form) {
      this.form = this.$options.data().form
      this.formLook = this.$options.data().formLook
      this.form.areaId = ''
      this.form.cityId = ''
      this.form.provinceId = ''
      // this.$refs[form].resetFields();
      this.addressCityId = []
      this.addressAreaId = []
      // this.$refs.form.clearValidate();
      //处理弹窗关闭时的报错
      // this.$refs[form].validate((valid) => {
      //       if (valid) {
      //         } else {
      this.$refs[form].resetFields();
      // 表单校验未通过，不执行提交操作
      // console.log('表单校验未通过');
      //     }
      // });
    },
    //新增
    addDialogVisible(form) {
      console.log(this.form);
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.parentId == '' || this.form.parentId == null) {
            this.form.parentId = 0
          }
          let obj = {
            // ...this.form,
            ...this.form,

            // parentId: this.parentId
          }

          console.log(obj);
          if (obj.opeComUserIds && obj.opeComUserIds.length > 0) {
            obj.opeComUserIds = obj.opeComUserIds.join(',')
          } else {
            obj.opeComUserIds = ''
          }
          // if (obj.companyTag == 2 && obj.opeComUserIds == '') {
          //   this.$message({
          //     message: '请选择联系人',
          //     type: 'warning',
          //     duration: 3000,
          //     customClass: "messageText",
          //   });
          //   return
          // }

          // console.log(objUpdate);
          let func = this.dialogTotalType == 0 ? companyInfoAdd(obj) : companyInfoUpdate(obj)
          func.then(res => {
            if (res.code == 200) {
              this.dialogVisible = false
              this.$message({
                message: res.message,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
              this.queryOperation()

            } else {
              this.$message({
                message: res.message,
                type: 'error',
                duration: 3000,
                customClass: "messageText",
              });
            }
          })

        } else {
          return false
        }


      });
    },
    //新增弹窗打开
    createInspection() {
      this.dialogVisible = true;

      this.dialogTotalType = 0
    },
    editDialog(el) {
      this.id = el.id
      this.dialogVisible = true
      if (el.parentId == 0) {
        el.parentId = null
      }
      console.log('el-111---', el);
      // this.form = Object.assign({}, el);
      if (el.opeComUserIdList && el.opeComUserIdList.length > 0) {
        this.form.opeComUserIds = el.opeComUserIdList.map(item => item.userId)
      }
        // if(el.parentId){
        //   this.setSelectedDetailsNode(el.parentId)
        // }else{  
          this.setSelectedDetailsNode(el.id)
        // }
      this.form.parentId = el.parentId
      this.form.provinceId = el.provinceId
      this.form.companyName = el.companyName,
      this.form.companyTag = el.companyTag,
      this.form.sort = el.sort,
      this.form.provinceId = el.provinceId
      this.form.address = el.address;
        if(el.contactsId){
        this.form.contacts = parseInt(el.contactsId);
        }else{
          this.form.contacts=''
        }
      this.form.remark = el.remark
        this.form.areaId = el.areaId
      this.form.cityId = el.cityId
      this.form.id = el.id
      this.dialogTotalType = 1
      // 
        
      // console.log(this.form);
      this.provinceIdChange(this.form.provinceId)
      //cityId   areaId
      this.cityIdChange(this.form.cityId)
    },
    lookDialog(el) {
      // if(el.parentId){
      //     this.setSelectedDetailsNode(el.parentId)
      //   }else{  
          this.setSelectedDetailsNode(el.id)
        // }
      this.dialogVisible3 = true
      this.formLook = Object.assign({}, el);
      if(el.contactsId){
        this.formLook.contacts = parseInt(el.contactsId);
        }
      if (el.opeComUserIdList && el.opeComUserIdList.length > 0) {
        this.formLook.opeComUserIds = el.opeComUserIdList.map(item => item.userId)
      }
      this.provinceIdChange(el.provinceId)
      //cityId   areaId
      this.cityIdChange(el.cityId)
      console.log(" this.formLook", this.formLook);
    },
    deleteDialog(item) {
      this.id = item.id;
      console.log(item);
      if(item.contactsId || item.opeComUserIdList&&item.opeComUserIdList.length > 0){
        this.$message({
            message: '该单位下有正在使用的用户，请解除绑定后操作',
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
      }else{
        this.deleteVisible = true;
      }
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      if (this.areaId.length == 3) {
        this.requestParam.areaId = this.areaId[2]
      } else {
        this.requestParam.areaId = null
      }
      if (this.areaId.length >= 2) {
        this.requestParam.cityId = this.areaId[1]
      } else {
        this.requestParam.cityId = null
      }
      if (this.areaId.length >= 1) {
        this.requestParam.provinceId = this.areaId[0]
      } else {
        this.requestParam.provinceId = null
      }
      console.log(this.areaId.le);
      this.queryOperation();
    },

    queryOperation() {
      this.dataLoading = true
      companyInfoList(this.requestParam).then(res => {
        this.dataLoading = false
        // console.log("22222222222222222222", this.requestParam);
        if (res.code == 200) {
          // 遍历输入的数据
          // console.log('111111111',res.data);
          //层级
          //  this.transformData(res.data)
          this.tableData = res.data
          //  console.log(this.tableData);

          this.total = res.total
          this.getCom()
        }
      })


    },
    transformData(data) {
      const map = {};
      const result = [];
      // 创建一个映射，方便通过 id 查找节点
      data.forEach(item => {
        map[item.id] = { ...item, children: [] };
      });
      // console.log('===>',map);
      // 将子节点添加到父节点的 children 中
      data.forEach(item => {
        if (item.parentId !== 0) {
          // console.log('1111111111111111111',map[item.parentId]);
          if (map[item.parentId]) {
            map[item.parentId].children.push(map[item.id]);
          } else {
            // result.push(map[item.id]);
          }
        } else {
          result.push(map[item.id]);
        }
      });

      this.tableData = result;
    },
    handleEdit(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryOperation();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryOperation();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryOperation();
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryOperation();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryOperation();
    },


    //删除
    deleteInspectionForm() {
      companyInfoDelete(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryOperation();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    //选择省
    provinceIdChange(el) {
      // console.log(this.address, el);
      if (this.form.provinceId) {
        const selectedOption = this.address.find(item => item.id == el);;
        console.log('111`~~~~~~~~~~11', selectedOption.regionEntityList);
        this.addressCityId = selectedOption.regionEntityList
      }
      if (this.formLook.provinceId) {
        const selectedOption = this.address.find(item => item.id == el);;
        console.log('111`~~~~~~~~~~11', selectedOption.regionEntityList);
        this.addressCityId = selectedOption.regionEntityList
      }
      // this.form.areaId=null
      // this.form.cityId=null

    },
    //选择市
    cityIdChange(el) {
      if (this.form.cityId) {
        const selectedOption = this.addressCityId.find(item => item.id == el);;
        console.log(selectedOption);
        this.addressAreaId = selectedOption.regionEntityList
        // console.log(this.addressAreaId);
      }
      // console.log(object);
      if (this.formLook.cityId) {
        const selectedOption = this.addressCityId.find(item => item.id == el);;
        console.log(selectedOption);
        this.addressAreaId = selectedOption.regionEntityList
        // console.log(this.addressAreaId);
      }
      // this.form.areaId=null

    },
    //选择省
    provinceIdChangeInput(el) {
      // console.log(this.address, el);
      if (this.form.provinceId) {
        const selectedOption = this.address.find(item => item.id == el);;
        console.log('111`~~~~~~~~~~11', selectedOption.regionEntityList);
        this.addressCityId = selectedOption.regionEntityList
      }
      if (this.form.areaId) {

        this.form.areaId = null
      }
      if (this.form.cityId) {

        this.form.cityId = null
      }

    },
    //选择市
    cityIdChangeInput(el) {
      if (this.form.cityId) {

        const selectedOption = this.addressCityId.find(item => item.id == el);;
        console.log(selectedOption);
        this.addressAreaId = selectedOption.regionEntityList
        // console.log(this.addressAreaId);
      }

      //   if(this.form.cityId){

      //   this.form.cityId = null
      // }

    },
    //获取单位标签list
    getUnitNameList() {
      searchDictCodeList({ dictCode: 'company_tag' }).then(res => {
        this.UnitNameList = res.data
      })
    },
    tableRadio(el) {
      console.log(el);
      if (this.selected) {
        this.parentId = el.id

      } else {
        this.parentId = 0

      }
    }
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
};
</script>
<style scoped>
#inspection-form {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  padding: 16px 20px;
  background-color: #061b45;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.query-button {
  display: flex;
  /* justify-content: space-between; */
}

.query-button :deep() .el-button {
  height: 35px;
  padding: 0 20px;
  background-color: #176ad2;
  text-align: center;
  line-height: 35px;
  font-size: 12px;
  color: #e8f4ff;
  border: 0;
}

.form :deep() .el-form-item {
  margin: 0 16px 12px 0;
  /* width: 100%; */
}

.form :deep().el-form-item__label {
  width: 61px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: left;
}

/* input */
.form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 180px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}
::v-deep .el-input__inner{
  height: 40px;
}

/* :deep() .el-input .el-input__inner{
  color:#aed6ff !important;
} */
.form :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

:deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

:deep() .el-textarea .el-textarea__inner::placeholder {
  font-size: 12px;
  color: #6180a8;
}

/* 下拉选择框 */
.el-select>>>.el-select-dropdown {
  background: #041c4c;
  border: 1px solid #09295b;
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.hover {
  background: #041c4c;
  color: #fff;
}

::v-deep .el-select-dropdown__item {
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.selected {
  color: #fff;
}

.el-select ::placeholder {
  color: #6180a8;
}

.el-select {
  width: 100%;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: #09224b;
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 12px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 暂无数据 */
::v-deep .el-table__body-wrapper {
  background: #071734;
}

::v-deep .el-table__empty-text {
  color: aliceblue;
  line-height: 150px;
}

.response-message {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.operate span {
  margin: 0 3px;
}

.tooltip {
  text-align: center;
}

/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination :deep() .el-pagination {
  padding-left: 0;
}

.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}

.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}

.el-pagination>>>.el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.jump-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-input {
  height: 30px;
  width: 35px;
  margin: 0 10px;
}

.pagination-input>>>.el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  padding: 0;
  text-align: center;
  color: #92b5da;
}

.pagination :deep().el-select .el-input__inner {
  height: 30px;

  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}

.pagination :deep() .el-pagination__sizes {
  margin-right: 0;
}

.jump-pagination .text {
  color: #92b5da;
  line-height: 30px;
}

.confirm {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0);
  background-color: #176ad2;
  font-family: "微软雅黑", sans-serif;
  color: #d7d7d7;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin-left: 5px;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}

::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  padding-top: 0;
}

.form-title {
  font-family: "Arial Bold", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  color: #c9d4f1;
  font-size: 16px;
  margin-bottom: 16px;
}

.dialog-content {
  margin: 18px 50px 0 47px;
}

.details-form :deep().el-form-item__label {
  width: 69px;
  color: #c9d4f1;
  font-size: 12px;
  text-align: right;
  margin-right: 28px;
}

.details-form :deep() .el-input .el-input__inner {
  width: 447px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.details-form :deep() .el-textarea .el-textarea__inner {
  width: 447px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.interval {
  width: 654px;
  height: 1px;
  background-color: #06224e;
  margin-bottom: 16px;
}

.details-form .default-inspection :deep() .el-input .el-input__inner {
  width: 651px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.details-form .selected-inspection:deep() .el-input .el-input__inner {
  width: 651px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: #041c4c;
}

.default-inspection :deep() .el-select-dropdown__item {
  height: 35px;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 1px solid #06224e;
  box-sizing: border-box;
  font-size: 12px;
  color: #aed6ff;
  font-weight: 300;
}

.details-form :deep() .el-select-dropdown__item {

  display: flex;
  justify-content: space-between;
}

::v-deep .el-popper[x-placement^="bottom"] .popper__arrow::after {
  top: 0;
  border-bottom-color: #041c4c;
}

.delete {
  font-size: 16px;
  font-weight: 700;
  color: #c9d4f1;
  margin: 42px 0 64px 39px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}

.tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
}


::v-deep .el-icon-arrow-right:before {
  color: #aed6ff;
  font-size: 16px;
}

::v-deep .el-textarea {
  width: 100%;
}

.place {
  width: 100%;
}

.myDialogForm>>>.el-form-item__label {
  color: #e8f4ff;
}

.myFormItem>>>.el-input__inner {

  background-color: #071734;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;

}

.myFormItem>>>.el-textarea__inner {

  background-color: #071734;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
  font-family: "Microsoft YaHei", Arial, sans-serif;
}

/* tableLoading字体设置 */
::v-deep .el-icon-loading {
  font-size: 18px;

}

::v-deep .el-loading-text {
  font-size: 18px;

}

.tableText {
  font-size: 16px;
  color: #aed6ff;

}

/* tableLoading字体设置 */
::v-deep .el-icon-loading {
  font-size: 18px;

}

::v-deep .el-loading-text {
  font-size: 18px;

}

.tableText {
  font-size: 16px;
  color: #aed6ff;

}

.mycascader>>>.el-input__inner {
  background-color: #071734;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

/* .mycascader:hover .el-input__inner {
  border-color: red;
} */

.mycascader .el-cascader {
  width: 100%;
}

.mycascader {
  width: 100%;
}
</style>
<style>
.cascaderPopper {
  background-color: #071734;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.cascaderPopper .el-cascader-menu {
  color: #aed6ff;

}

.cascaderPopper .el-cascader-node.is-selectable.in-active-path {
  color: #409EFF;
}

.cascaderPopper .el-cascader-node:hover {
  background: #09224B !important;
}

.cascaderPopper .el-cascader-node:focus {
  background: #09224B !important;
}

.cascaderPopper .popper__arrow::after {
  border-bottom-color: #071734 !important;
}

.cascaderPopper {
  width: 600px;
  overflow: auto;

}

.cascaderPopper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

.cascaderPopper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

.table :deep() .el-table__body tr:hover  td:first-child{
   border-left-color:#176ad2 !important;
   border-left-width:2px !important;
  }
/* .el-select__tags >>> .el-tag--info{
  background: #2142A1 !important;
  color: red !important;
} */
</style>
